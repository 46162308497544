import { Component, OnInit } from '@angular/core';
import { MetaService } from '@shared/services/meta/meta.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthFacade } from '@domains/auth/auth.facade';
import moment from 'moment';
import { createMetaTag } from '@shared/services/meta/meta.constant';
import defaultLanguage from '../assets/locale/ja.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private metaService: MetaService,
    private translate: TranslateService,
    private router: Router,
    private authFacade: AuthFacade
  ) { }

  ngOnInit(): void {
    this.translate.setTranslation('ja', defaultLanguage);
    this.translate.setDefaultLang('ja');
    this.translate.use('ja');

    moment.locale('ja');

    this.metaService.injectMetaTag();

    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.checkUrl(event.urlAfterRedirects);
      this.setTitle(event.urlAfterRedirects);
    });
  }

  checkUrl(currentUrl: string) {

    // 代理ログイン中は特定の画面へのアクセスを禁止する。
    const excludeUrls = [
      '/account/edit', // 自社情報の確認・編集画面
      '/consult/list' // お問い合わせ一覧
    ]

    excludeUrls.forEach((url: string) => {
      if (currentUrl.includes(url))
        // 禁止した画面にアクセスした場合は強制的に代理ログアウトする。
        this.authFacade.proxyLogout();
    });

    // // 代理ログイン中は事業詳細画面へのアクセスを禁止する。
    // const pathReqProjectDetail = this.extractRequestProjectPath(currentUrl);
    // if (pathReqProjectDetail != '') {
    //   this.authFacade.proxyLogout();
    // }
  }

  setTitle(currentUrl: string): void {
    this.authFacade.delegateLoggedIn$.subscribe(loggedIn => {
      if (loggedIn) {

        // 代理ログイン中に「マイページ」にアクセスした場合、ブラウザのタブに「委任元事業一覧」と表示する
        if (currentUrl == '/request-project/list') {
          document.title = createMetaTag('委任元事業一覧').title;
        }

        this.authFacade.delegateUser$.subscribe(user => {
          if (user && 'firm_nm' in user) {
            document.title = `[${user.firm_nm}]${document.title}`;
          }
        });
      }
    });
  }

  /** URLから/request-project/{sfid}を抽出する */
  private extractRequestProjectPath(url: string): string {
    const regex = /\/request-project\/([0-9a-zA-Z]{18})/;
    const match = url.match(regex);

    return match ? match[0] : "";
  }
}
