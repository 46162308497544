import { Injectable } from '@angular/core';
import { CompanyStoreModule } from './store/company.store.module';
import { Store, select } from '@ngrx/store';
import * as CompanyActions from './store/company.action';
import * as CompanySelector from './store/company.selector';
import { State } from './store/company.state';
import { CompanyApiService } from './company.api.service';
import { ICompanyInfo, PrefectureType } from '@interface/company.interface';
import { IGbizUserInfo } from '@interface/gbiz.interface';
import { IMirasapoPlusInfo } from '@interface/mirasapo-plus.interface';

@Injectable({
  providedIn: CompanyStoreModule,
})
export class CompanyFacade {
  readonly company$ = this.store.pipe(select(CompanySelector.getCompany));
  readonly mirasapoPlusInfo$ = this.store.pipe(select(CompanySelector.getMirasapoPlusInfo));

  constructor(private store: Store<State>, private api: CompanyApiService) {}

  /**
   * gBizUserInfoをMuleに連携
   * @param info gBizUserInfo
   */
  async saveGBizUserInfo(info: IGbizUserInfo, doDispatch: boolean = true): Promise<void> {
    // Note: ワンスオンリー用に事業形態はラベルに変換する
    // Note: gBizのUserInfoのparent_idはドキュメントではStringだが、実際はNumberが送られてくるため変換
    // Note: ワンスオンリー用に都道府県コードは都道府県名ラベルに変換する
    const companyInfo: ICompanyInfo = {
      ...info,
      corp_type: info.corp_type === '1' ? '法人' : '個人事業主',
      parent_id: info?.parent_id ? `${info.parent_id}` : undefined,
      prefecture_name: PrefectureType[info.prefecture_name],
      user_prefecture_name: PrefectureType[info.user_prefecture_name],
    };
    const company = await this.api.saveCompanyInfo(companyInfo);

    if (doDispatch)
      this.store.dispatch(CompanyActions.setCompanyInfo({ company }));
  }

  /**
   * 追加文含めた事業者情報をMuleに連携する
   * @param info ICompanyInfo
   */
  async saveAdditionalCompanyInfo(info: ICompanyInfo): Promise<void> {
    const company = await this.api.saveCompanyInfo(info);
    this.store.dispatch(CompanyActions.setCompanyInfo({ company }));
  }

  /**
   * ミラサポplusから情報を取得する
   */
  async getMirasapoPlusInfo(employeesNumber: number): Promise<boolean> {
    const mirasapoPlusInfo: IMirasapoPlusInfo = await this.api.getMirasapoPlusInfo(employeesNumber);

    // 全項目が空か？
    const isEmpty: boolean = Object.values(mirasapoPlusInfo).every((value) => !value);

    // 空でない項目が1つ以上あれば画面に反映する
    if (!isEmpty) {
      this.store.dispatch(CompanyActions.setMirasapoPlusInfo({ mirasapoPlusInfo }));
    }

    return isEmpty;
  }
}
