import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, featureName } from './auth.state';

const getState = createFeatureSelector<State>(featureName);
export const getLoggedIn = createSelector(
  getState,
  state => state.loggedIn
);
export const getUserProfile = createSelector(
  getState,
  state => state.userProfile
);
export const getDelegateLoggedIn = createSelector(
  getState,
  state => state.delegateLoggedIn
);
export const getDelegateUser = createSelector(
  getState,
  (state) => state.delegateUser
);
