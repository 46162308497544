import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { State } from './store/auth.state';
import { AuthService } from './auth.gbiz.service';
import { AuthApiService } from './auth-api.service';
import * as AuthActions from './store/auth.action';
import * as AuthSelector from './store/auth.selector';
import { AuthStoreModule } from './store/auth-store.module';
import { PrefectureCode } from '@interface/company.interface';
import {
  IGbizConfig,
  IGbizDelegationInfo,
  IGbizDelegationUserInfo,
  IGbizToken,
  IGbizUserInfo,
} from '@interface/gbiz.interface';
import {
  IProxyAuthorizationToken,
  IValidateDelegationRequest
} from '@interface/delegation.interface';
import { HttpClient } from '@angular/common/http';
import { Subscription, interval, timer } from 'rxjs';
import { ForceReloadException } from '@shared/exception/force-reload.exception';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: AuthStoreModule,
})
export class AuthFacade {
  readonly loggedIn$ = this.store.pipe(select(AuthSelector.getLoggedIn));
  readonly userProfile$ = this.store.pipe(select(AuthSelector.getUserProfile));
  readonly delegateLoggedIn$ = this.store.pipe(select(AuthSelector.getDelegateLoggedIn));
  readonly delegateUser$ = this.store.pipe(select(AuthSelector.getDelegateUser));

  protected accessTokenRefreshSubscription: Subscription;
  protected timeoutCheckerSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private store: Store<State>,
    private authApi: AuthApiService
  ) {
    console.log('[AuthFacade] constructor');

    this.initFacade();
  }

  private initFacade() {
    // SessionStorageにトークンやプロファイルが入っていたらStoreに書き戻しておく
    if (this.gBizUser.sub) {
      // ストアに保存する
      if (this.proxyAuthorizationToken.proxyAuthorizationToken) {
        // 代理ログイン中の場合
        this.setAuth(this.gBizUser, this.delegateUser);
      } else {
        this.setAuth(this.gBizUser, null);
      }

      // アクセストークンの自動リフレッシュタイマー起動
      this.setupRefreshTokenTimer(this.gBizToken.expires_in);
    }
  }

  private async getConfig() {
    const configJson = await this.authApi.getConfig().toPromise();
    this.gBizConfig = JSON.parse(configJson);
    return this.gBizConfig;
  }

  private async getService() {
    if (!this.gBizConfig.clientId) {
      return new AuthService(this.http, await this.getConfig());
    } else {
      return new AuthService(this.http, this.gBizConfig);
    }
  }

  public get isTimeout(): boolean {
    const timeoutLimit = sessionStorage.getItem('timeoutLimit');
    const limit = parseInt(timeoutLimit) || 0;
    return limit <= Date.now();
  }

  setTimeoutLimit(timeoutSeconds: number): void {
    const timeoutLimit = Date.now() + timeoutSeconds * 1000;
    sessionStorage.setItem('timeoutLimit', timeoutLimit.toString());
  }

  public get gBizConfig(): IGbizConfig {
    const gBizConfigString = sessionStorage.getItem('gBizConfig');
    const gBizConfig: IGbizConfig = JSON.parse(gBizConfigString || '{}');
    return gBizConfig;
  }

  public set gBizConfig(value: IGbizConfig) {
    const gBizConfigString: string = JSON.stringify(value);
    sessionStorage.setItem('gBizConfig', gBizConfigString);
  }

  public get gBizToken(): IGbizToken {
    const jsonString = sessionStorage.getItem('gBizToken');
    return JSON.parse(jsonString || '{}') as IGbizToken;
  }

  public set gBizToken(value: IGbizToken) {
    const jsonString: string = JSON.stringify(value);
    sessionStorage.setItem('gBizToken', jsonString);
  }

  public get gBizUser(): IGbizUserInfo {
    const jsonString = sessionStorage.getItem('gBizUserInfo');
    return JSON.parse(jsonString || '{}') as IGbizUserInfo;
  }

  public set gBizUser(value: IGbizUserInfo) {
    const jsonString: string = JSON.stringify(value);
    sessionStorage.setItem('gBizUserInfo', jsonString);
  }

  public get delegateUser(): IGbizDelegationUserInfo {
    const jsonString = sessionStorage.getItem('delegateUser');
    return JSON.parse(jsonString || '{}') as IGbizDelegationUserInfo;
  }

  public set delegateUser(value: IGbizDelegationUserInfo) {
    const jsonString: string = JSON.stringify(value);
    sessionStorage.setItem('delegateUser', jsonString);
  }

  public get containDelegationUsers(): boolean {

    return this.delegationInfo?.delegation_info?.length >= 1;
  }

  public get delegationInfo(): IGbizDelegationInfo {
    const jsonString = sessionStorage.getItem('delegateUsers');
    return JSON.parse(jsonString || '{}') as IGbizDelegationInfo;
  }

  public set delegationInfo(value: IGbizDelegationInfo) {
    const jsonString: string = JSON.stringify(value);
    sessionStorage.setItem('delegateUsers', jsonString);
  }

  public get proxyAuthorizationToken(): IProxyAuthorizationToken {
    const jsonString = sessionStorage.getItem('proxyAuthorizationToken');
    return JSON.parse(jsonString || '{}') as IProxyAuthorizationToken;
  }

  public set proxyAuthorizationToken(value: IProxyAuthorizationToken) {
    const jsonString: string = JSON.stringify(value);
    sessionStorage.setItem('proxyAuthorizationToken', jsonString);
  }

  async handleAuthentication(code: string): Promise<IGbizUserInfo> {
    try {
      const svc = await this.getService();

      // 認証コードを使って、アクセストークンを取得する
      const token = await svc.getAccessToken(code).toPromise();

      // 取得した内容をStoreする
      this.gBizToken = token;

      // アクセストークンを使ってユーザー情報を取得する
      const user = await svc.getGbizUserInfo(token.access_token).toPromise();

      // 取得した内容をStoreする
      this.gBizUser = user;

      // GビズIDはリフレッシュトークンの有効時間が非常に長い（30日）
      // 個別のクライアントに対して有効期限の長さを調整できないため
      // クライアント側で独自にタイムアウト時間を設定する
      this.setTimeoutLimit(this.gBizConfig.sessionTimeoutSeconds);

      // ファサードを再初期化
      this.initFacade();

      // 後続処理でgBizUserInfoを使用するためreturnする
      return this.gBizUser;
    } catch (e) {
      // ログインに失敗したらアプリをリセットする
      throw new ForceReloadException(e.message, e);
    }
  }

  async login(redirectPath?: string) {
    const svc = await this.getService();
    svc.login(redirectPath);
  }

  // ログアウト
  async logout() {
    const svc = await this.getService();
    svc.logout();
  }

  // GビズIDのID作成画面を直接開く
  async signup() {
    // ログアウト直後に実施されるとConfigデータが無い
    const config = await this.getConfig();
    window.open(config.signupUrl);
  }

  clear() {
    this.clearRefreshTokenTimer();
    this.clearAuth();
    sessionStorage.clear();
  }

  private async setAuth(
    userProfile: IGbizUserInfo,
    delegateUser: IGbizDelegationUserInfo
  ): Promise<void> {

    this.store.dispatch(
      AuthActions.setAuth({
        loggedIn: true,
        userProfile,
        delegateLoggedIn: delegateUser != null,
        delegateUser,
      })
    );
  }

  private async clearAuth(): Promise<void> {
    this.store.dispatch(
      AuthActions.setAuth({
        loggedIn: false,
        userProfile: null,
        delegateLoggedIn: false,
        delegateUser: null,
      })
    );
  }

  async saveDelegationInfo(): Promise<IGbizDelegationInfo> {
    // GビズIDから委任元の情報を取得する。
    const delInfo = await this.authApi.getDelegationInfo().toPromise();
    this.delegationInfo = delInfo;

    // 委任元情報リストをセッションストレージに登録したら、代理ログイン状態を解除する。
    this.proxyLogout();

    return delInfo;
  }

  /** 委任関係が正常が確認する。 */
  async validateDelegation(): Promise<boolean> {

    const apiResult = await this.authApi.validateDelegation(<IValidateDelegationRequest>{
      delegateMetiId: this.delegateUser.meti_id
    }).toPromise();

    return apiResult.isRight;
  }

  // 代理ログイン
  public async proxyLogin(metiId?: number, requestFormInputDataSfid?: string): Promise<boolean> {
    if (!metiId && !requestFormInputDataSfid) {
      return false;
    }

    const isLoggedIn = await this.loggedIn$.pipe(first()).toPromise();

    if (!isLoggedIn) {
      return false;
    }

    this.proxyLogout();

    let delegateUser;
    let response;

    if (metiId) {
      delegateUser = this.delegationInfo?.delegation_info?.find((entity: IGbizDelegationUserInfo) => entity.meti_id === metiId) ?? null;
      response = await this.authApi.getProxyAuthorizationToken({ delegateEmail: delegateUser.user_email }).toPromise();
    } else {
      response = await this.authApi.getProxyAuthorizationToken({ requestFormInputDataSfid: requestFormInputDataSfid }).toPromise();
      metiId = Number(response.delegateAccountNumber);
      delete response.delegateAccountNumber;
      delegateUser = this.delegationInfo?.delegation_info?.find((entity: IGbizDelegationUserInfo) => entity.meti_id === metiId) ?? null;
    }

    if (!delegateUser) {
      return false;
    }

    this.proxyAuthorizationToken = response;
    this.delegateUser = delegateUser;
    this.setAuth(this.gBizUser, delegateUser);
    return true;
  }

  // 代理ログアウト
  public proxyLogout() {
    sessionStorage.removeItem('proxyAuthorizationToken');
    sessionStorage.removeItem('delegateUser');
    this.setAuth(this.gBizUser, null);
  }

  protected clearRefreshTokenTimer(): void {
    if (this.timeoutCheckerSubscription) {
      this.timeoutCheckerSubscription.unsubscribe();
    }

    if (this.accessTokenRefreshSubscription) {
      this.accessTokenRefreshSubscription.unsubscribe();
    }
  }

  protected setupRefreshTokenTimer(expires_in: string): void {
    // 登録済みタイマーをクリアする
    this.clearRefreshTokenTimer();

    // トークン再取得をスケジュールする時間を計算する
    let intervalTime = parseInt(expires_in) || 120;

    // ローカルタイムアウトが短ければそちらにする
    if (intervalTime > this.gBizConfig.sessionTimeoutSeconds) {
      intervalTime = this.gBizConfig.sessionTimeoutSeconds;
    }

    // 有効期限が完全に切れるまえにトークンを取得するために半分にする
    intervalTime = intervalTime / 2;

    // アクセストークンは一定時間後にリフレッシュ
    this.accessTokenRefreshSubscription = interval(intervalTime * 1000).subscribe(async () => {
      try {
        const svc = await this.getService();
        const token = await svc.refreshAccessToken(this.gBizToken.refresh_token).toPromise();
        this.gBizToken = token;

        // 代理ログイン用Tokenリフレッシュ
        if (this.proxyAuthorizationToken.proxyAuthorizationToken) {
          const token = await this.authApi.getProxyAuthorizationToken({ delegateEmail: this.delegateUser.user_email }).toPromise();
          this.proxyAuthorizationToken = token;
        }

      } catch (e) {
        // メッセージダイアログを放置し続けるとまた同じExceptionが発生するのでタイマー処理のみクリアする
        // セッション状態のクリアはあくまでもGビズIDからのリダイレクトURLを持って行う
        this.clearRefreshTokenTimer();
        // トークンリフレッシュに失敗したらアプリをリセットする
        throw new ForceReloadException(e.message, e);
      }
    });

    // タイムアウトかどうかを1分感覚でチェックする
    this.timeoutCheckerSubscription = timer(0, 60 * 1000).subscribe(async () => {
      if (this.isTimeout) {
        // メッセージダイアログを放置し続けるとまた同じExceptionが発生するのでタイマー処理のみクリアする
        // セッション状態のクリアはあくまでもGビズIDからのリダイレクトURLを持って行う
        this.clearRefreshTokenTimer();
        // クライアント独自のタイムアウト時間を超えている
        throw new ForceReloadException('MESSAGE.TOKEN_IS_EXPIRED');
      }
    });
  }

  private getCorpType(businessType: string): string {
    if (businessType == '法人')
      return '1';
    else if (businessType == '個人事業主')
      return '2';
    else
      return '';
  }

  /**
   * 委任元情報ユーザデータをGビズアカウント情報形式に変換する。
   * IGbizDelegationUserInfo → IGbizUserInfo
   */
  convertToGbizUserInfo(delegationUserInfo: IGbizDelegationUserInfo): IGbizUserInfo {
    return <IGbizUserInfo>{
      sub: delegationUserInfo.meti_id.toString(), // アカウント管理番号
      account_type: '', // アカウント種別
      corp_type: this.getCorpType(delegationUserInfo.business_type), // 事業形態
      //corp_type: delegationUserInfo.business_type, // 事業形態
      corporate_number: delegationUserInfo.corp_no, // 【基本情報】法人番号/個人事業主管理番号
      name: delegationUserInfo.firm_nm, // 【基本情報】法人名/屋号
      en_name: delegationUserInfo.firm_nm_en, // 【基本情報】法人名/屋号（英語表記）
      prefecture_name: PrefectureCode[delegationUserInfo.address_pref], // 【基本情報】本店所在地/印鑑登録証明書住所（都道府県）
      address1: delegationUserInfo.address_city, // 【基本情報】本店所在地/印鑑登録証明書住所（市区町村）
      address2: delegationUserInfo.address_number, // 【基本情報】本店所在地/印鑑登録証明書住所（番地等）
      rep_last_nm: delegationUserInfo.last_nm, // 【基本情報】代表者名/個人事業主氏名（姓）
      rep_first_nm: delegationUserInfo.first_nm, // 【基本情報】代表者名/個人事業主氏名（名）
      rep_last_nm_kana: delegationUserInfo.last_nm_kana, // 【基本情報】代表者名フリガナ/個人事業主氏名フリガナ（姓）
      rep_first_nm_kana: delegationUserInfo.first_nm_kana, // 【基本情報】代表者名フリガナ/個人事業主氏名フリガナ（名）
      birthday_ymd: delegationUserInfo.birthday, // 【基本情報】代表者姓年月日/個人事業主生年月日 yyyyMMdd
      user_last_nm: delegationUserInfo.user_last_nm, // 【利用者情報】アカウント利用者氏名（姓）
      user_first_nm: delegationUserInfo.user_first_nm, // 【利用者情報】アカウント利用者氏名（名）
      user_last_nm_kana: delegationUserInfo.user_last_nm_kana,  // 【利用者情報】アカウント利用者氏名フリガナ（姓）
      user_first_nm_kana: delegationUserInfo.user_first_nm_kana, // 【利用者情報】アカウント利用者氏名フリガナ（名）
      user_post_code: delegationUserInfo.user_post_cd, // 【利用者情報】連絡先郵便番号（ハイフンは含まない）
      user_prefecture_name: PrefectureCode[delegationUserInfo.user_address_pref], // 【利用者情報】連絡先住所（都道府県）
      user_address1: delegationUserInfo.user_address_city, // 【利用者情報】連絡先住所（市区町村）
      user_address2: delegationUserInfo.user_address_number, // 【利用者情報】連絡先住所（番地等）
      user_address3: delegationUserInfo.user_address_bldg, // 【利用者情報】連絡先住所（マンション名等）
      user_department: delegationUserInfo.user_department, // 【利用者情報】会社部署名／部署名
      user_tel_no_contact: delegationUserInfo.user_tel_no_contact, // 【利用者情報】連絡先電話番号（ハイフンは含まない）
      user_email: delegationUserInfo.user_email, // 【利用者情報】アカウントID
    };
  }
}
