import { IGbizUserInfo, IGbizDelegationUserInfo } from '@interface/gbiz.interface';

export const featureName = 'auth';
export interface State {
  loggedIn: boolean;
  userProfile: IGbizUserInfo;
  delegateLoggedIn: boolean;
  delegateUser: IGbizDelegationUserInfo;
}
export const initialState: State = {
  loggedIn: false,
  userProfile: null,
  delegateLoggedIn: false,
  delegateUser: null,
};
