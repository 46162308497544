import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthStoreModule } from './store/auth-store.module';
import { IGbizDelegationInfo } from '@interface/gbiz.interface';
import {
  IProxyAuthorizationToken,
  IProxyAuthorizationTokenCondition,
  IValidateDelegationRequest,
  IValidateDelegationResult
} from '@interface/delegation.interface';

@Injectable({
  providedIn: AuthStoreModule,
})
export class AuthApiService {
  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string) { }

  getConfig(): Observable<string> {
    return this.http.get<string>('/authconfig.json');
  }

  getDelegationInfo(): Observable<IGbizDelegationInfo> {
    const url = `${this.apiUrl}/delegation/info`;
    return this.http.post<IGbizDelegationInfo>(url, {});
  }

  getProxyAuthorizationToken(condition: IProxyAuthorizationTokenCondition): Observable<IProxyAuthorizationToken> {
    const url = `${this.apiUrl}/delegation/authorization`;
    return this.http.post<IProxyAuthorizationToken>(url, condition);
  }

  validateDelegation(condition: IValidateDelegationRequest ): Observable<IValidateDelegationResult> {
    const url = `${this.apiUrl}/delegation/validate`;
    return this.http.post<IValidateDelegationResult>(url, condition);
  }
}